import React from "react";
import "./App.scss";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Outlet,
} from "react-router-dom";
//Components
import Header from "./Components/MainHeader";
import Footer from "./Components/MainFooter";

//Page
import HomePage from "./Pages/Homepage";
import EventPage from "./Pages/EventPage";
import BlogPage from "./Pages/BlogPage";
import AboutUs from "./Pages/AboutUsPage";
import ContactPage from "./Pages/ContactPage";
import TermsofUse from "./Pages/TermsofUsePage";
import CancellationRefundPolicy from "./Pages/CancellationRefundPolicy";
import CookiesPolicy from "./Pages/CookiePolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

//create routes
function App() {
  const ROUTER = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<HomePage />} />
        <Route path="/event" element={<EventPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/terms-of-use" element={<TermsofUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/cancellation-refund-policy"
          element={<CancellationRefundPolicy />}
        />
        <Route path="/cookie-policy" element={<CookiesPolicy />} />
      </Route>,
    ),
  );

  return <RouterProvider router={ROUTER} />;
}

function Root() {
  return (
    <div className="App">
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
