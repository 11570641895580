import React from "react";
import { Row, Col } from "react-bootstrap";
import "./style.scss";

function index(props) {
  return (
    <Row
      className={`${
        props?.align === "center"
          ? "d-flex flex-column pb-5 align-items-center pb-4"
          : "d-flex flex-column pb-5 align-items-start pb-4"
      }`}
    >
      <Col xxl={6} xl={6} md={12} sm={12}>
        <div
          className={`${
            props?.align === "center"
              ? "d-flex flex-column pb-5 align-items-center pb-4"
              : "d-flex flex-column pb-5 align-items-start pb-4"
          }`}
        >
          <h2 className="section_header pb-4">{props?.header}</h2>
          <span className="section_subtitle">{props?.subtitle}</span>
        </div>
      </Col>
    </Row>
  );
}

export default index;
