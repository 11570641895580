import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CardBox from "../../../../Base-Components/EventCard";
import SectionHeader from "../../../../Base-Components/SectionHeader";

function Featured() {
  return (
    <Container fluid className="pt-4 pb-4">
      <Container>
        <SectionHeader
          align="center"
          header="Featured Tours"
          subtitle="Our Featured Tours can help you find the trip that's perfect for you!"
        />
        <Row className="">
          <Col
            xxl={3}
            xl={3}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="pt-2 pb-2"
          >
            <CardBox />
          </Col>
          <Col
            xxl={3}
            xl={3}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="pt-2 pb-2"
          >
            <CardBox />
          </Col>
          <Col
            xxl={3}
            xl={3}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="pt-2 pb-2"
          >
            <CardBox />
          </Col>
          <Col
            xxl={3}
            xl={3}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="pt-2 pb-2"
          >
            <CardBox />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Featured;
