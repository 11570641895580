import React from "react";
import Corausel from "./Components/Corausel";
import Featured from "./Components/Featured";
import Statistics from "./Components/Statistic";

function index() {
  return (
    <div className="page_container">
      <Corausel />
      <Statistics />
      <Featured />
    </div>
  );
}

export default index;
