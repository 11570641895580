import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import Logo from "../../Assets/Brand/JumuikaLogo.svg";

function NavHeader() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      let header = document.querySelector(".main_navbar");
      if (window.scrollY > 200) {
        header?.classList.add("scrolled");
      } else {
        header?.classList.remove("scrolled");
      }
    });
  }, []);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="top">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
      {/* The canvas ends here */}
      <Navbar
        id="navbar"
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary main_navbar"
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="#home">
            <img alt="" width={90} src={Logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          {/* <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/event">Event</Nav.Link>
              <Nav.Link href="/blog">Blogs</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>
              <Nav.Link href="/contact">Contacts</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link eventKey={2} onClick={handleShow} href="#memes">
                <i class="bi bi-search"></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
    </>
  );
}

export default NavHeader;
