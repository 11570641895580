import React from "react";
import { Container, Stack } from "react-bootstrap";

function index() {
  return (
    <Container fluid className="main_container">
      <Container>
        <div className="pt-2 pb-3">
          <h3 className="page_header">Terms of Use</h3>
        </div>
        <Stack gap={3}>
          <div>
            These terms of use (the " Terms of Use") govern your use of our
            website www.jumuika.online (the " Website") and our "
            <span className="fw-medium">Jumuika Online</span>" application for
            mobile and handheld devices (the " App"). The Website and the App
            are jointly referred to as the "Platform". Please read these Terms
            of Use carefully before you use the services. If you do not agree to
            these Terms of Use, you may not use the services on the Platform,
            and we request you to uninstall the App. By installing, downloading
            or even merely using the Platform, you shall be contracting with{" "}
            <span className="fw-medium">Jumuika Online</span> and you signify
            your acceptance to the Terms of Use and other{" "}
            <span className="fw-medium">
              <span className="fw-medium">Jumuika Online</span> Policies
            </span>
            (including but not limited to the Cancellation & Refund Policy,
            Privacy Policy and Take Down Policy) as posted on the Platform from
            time to time, which takes effect on the date on which you download,
            install or use the Services, and create a legally binding
            arrangement to abide by the same.
          </div>
          <div>
            The Platform is operated and owned by{" "}
            <span className="fw-medium">
              <span className="fw-medium">Jumuika Online</span>
            </span>
            , a company incorporated and having its registered office at 1736,
            Kahama Court ,Kahama Road,Dares salaam, P.O.Box 20332. For the
            purpose of these Terms of Use, wherever the context so requires,
            "you" shall mean any natural or legal person who has agreed to
            become a buyer or customer on the Platform by providing Registration
            Data while registering on the Platform as a registered user using
            any computer systems. The terms "
            <span className="fw-medium">Jumuika Online</span>
            ", "we", "us" or "our" shall mean{" "}
            <span className="fw-medium">
              <span className="fw-medium">Jumuika Online</span>
            </span>
            .
          </div>
          <div>
            <span className="fw-medium">Jumuika Online</span> enables
            transactions between participant restaurants/merchants and buyers,
            dealing in prepared food and beverages (" Platform Services"). The
            buyers (" Buyer/s") can choose and place orders (" Orders") from
            variety of products listed and offered for sale by various
            neighbourhood merchants including but not limited to the restaurants
            and eateries (" Merchant/s"), on the Platform and{" "}
            <span className="fw-medium">Jumuika Online</span> enables delivery
            of such orders at select localities of serviceable cities across
            Africa (" Delivery Services"). The Platform Services and Delivery
            Services are collectively referred to as "Services". For the
            delivery services rendered,{" "}
            <span className="fw-medium">Jumuika Online</span> may charge you
            delivery fee (inclusive of applicable taxes whenever not expressly
            mentioned)
          </div>
          <div>
            <div>
              <h4 className="Simple_header">I. AMENDMENTS</h4>
            </div>
            These Terms of Use are subject to modifications at any time. We
            reserve the right to modify and or change these Terms of Use and
            other{" "}
            <span className="fw-medium">
              <span className="fw-medium">Jumuika Online</span> Policies
            </span>{" "}
            at any time (In accordance to Tanzanian Law) by posting changes on
            the Platform, and you shall be liable to update yourself of such
            changes, if any, by accessing the changes on the Platform. You
            shall, at all times, be responsible for regularly reviewing the
            Terms of Use and the other{" "}
            <span className="fw-medium">Jumuika Online</span> policies and note
            the changes made on the Platform. Your continued usage of the
            services after any change is posted constitutes your acceptance of
            the amended Terms of Use and other{" "}
            <span className="fw-medium">Jumuika Online</span> policies. As long
            as you comply with these Terms of Use,{" "}
            <span className="fw-medium">Jumuika Online</span> grants you a
            personal, non-exclusive, non-transferable, limited privilege to
            access, enter, and or use the Platform. By accepting these Terms of
            Use, you also accept and agree to bound by all and other terms and
            conditions and{" "}
            <span className="fw-medium">
              <span className="fw-medium">Jumuika Online</span> Policies
            </span>
            (including but not limited to Cancellation & Refund Policy, Privacy
            Policy and Notice and Take Down Policy) as may be posted on the
            Platform from time to time.
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                II. Use of Platform and Services
              </h4>
            </div>
            <ul>
              <li>
                All commercial/contractual terms are offered by and agreed to
                between Buyers and Merchants alone. The commercial/contractual
                terms include without limitation price, taxes, shipping costs,
                payment methods, payment terms, date, period and mode of
                delivery, warranties related to products and services and after
                sales services related to products and services.{" "}
                <span className="fw-medium">Jumuika Online</span>
                does not have any control or does not determine or advise or in
                any way involve itself in the offering or acceptance of such
                commercial/contractual terms between the Buyers and Merchants.
                <span className="fw-medium">Jumuika Online</span> may, however,
                offer support services to Merchants in respect to order
                fulfilment, payment collection, call centre, and other services,
                pursuant to independent contracts executed by it with the
                Merchants.
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> does not make
                any representation or warranty as to the item-specifics (such as
                legal title, creditworthiness, identity, etc.) of any of the
                Merchants. You are advised to independently verify the bona
                fides of any particular Merchant that you choose to deal with on
                the Platform and use your best judgment in that behalf. All
                Merchant offers and third party offers are subject to respective
                party terms and conditions.
                <span className="fw-medium">Jumuika Online</span> takes no
                responsibility for such offers.
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> neither make
                any representation or warranty as to specifics (such as quality,
                value, saleability, etc.) of the products or services proposed
                to be sold or offered to be sold or purchased on the Platform
                nor does implicitly or explicitly support or endorse the sale or
                purchase of any products or services on the Platform.{" "}
                <span className="fw-medium">Jumuika Online</span> accepts no
                liability for any errors or omissions, whether on behalf of
                itself or third parties.
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> is not
                responsible for any non-performance or breach of any contract
                entered into between Buyers and Merchants on the Platform.{" "}
                <span className="fw-medium">Jumuika Online</span> cannot and
                does not guarantee that the concerned Buyers and/or Merchants
                will perform any transaction concluded on the Platform.{" "}
                <span className="fw-medium">Jumuika Online</span> is not
                responsible for unsatisfactory or non-performance of services or
                damages or delays as a result of products which are out of
                stock, unavailable or back ordered.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> is operating
                an online marketplace and assumes the role of facilitator, and
                does not at any point of time during any transaction between
                Buyer and Merchant on the Platform come into or take possession
                of any of the products or services offered by Merchant. At no
                time shall <span className="fw-medium">Jumuika Online</span>
                hold any right, title or interest over the products nor shall
                <span className="fw-medium">Jumuika Online</span> have any
                obligations or liabilities in respect of such contract entered
                into between Buyer and Merchant.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> is only
                providing a platform for communication and it is agreed that the
                contract for sale of any of the products or services shall be a
                strictly bipartite contract between the Merchant and the Buyer.
                In case of complaints from the Buyer pertaining to food
                efficacy, quality, or any other such issues,{" "}
                <span className="fw-medium">Jumuika Online</span> shall notify
                the same to Merchant and shall also redirect the Buyer to the
                consumer call centre of the Merchant. The Merchant shall be
                liable for redressing Buyer complaints. In the event you raise
                any complaint on any Merchant accessed using our Platform, we
                shall assist you to the best of our abilities by providing
                relevant information to you,such as details of the Merchant and
                the specific Order to which the complaint relates, to enable
                satisfactory resolution of the complaint.
              </li>
              <li>
                Please note that there could be risks in dealing with underage
                persons or people acting under false pretence.
              </li>
            </ul>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                III. <span className="fw-medium">Jumuika Online</span> - Use of
                the Website and Apps (Android and iOS)
              </h4>
            </div>
            You agree, undertake and confirm that your use of Platform shall be
            strictly governed by the following binding principles:
            <ol>
              <li>
                You shall not host, display, upload, download, modify, publish,
                transmit, update or share any information which:
                <ol>
                  <li>
                    Belongs to another person and which you do not have any
                    right to;
                  </li>
                  <li>
                    Is grossly harmful, harassing, blasphemous, defamatory,
                    obscene, pornographic, paedophilic, libellous, slanderous,
                    criminally inciting or invasive of another's privacy,
                    hateful, or racially, ethnically objectionable, disparaging,
                    relating or encouraging money laundering or gambling, or
                    otherwise unlawful in any manner whatsoever; or unlawfully
                    threatening or unlawfully harassing including but not
                    limited to "indecent representation of women" within the
                    meaning of the Indecent Representation of Women
                    (Prohibition)
                  </li>
                  <li>
                    Is misleading or misrepresentative in any way; and or / is
                    patently offensive to the online community, such as sexually
                    explicit content, or content that promotes obscenity,
                    paedophilia, racism, bigotry, hatred or physical harm of any
                    kind against any group or individual;{" "}
                  </li>
                  <li>Harasses or advocates harassment of another person; </li>
                  <li>
                    Involves the transmission of "junk mail", "chain letters",
                    or unsolicited mass mailing or "spamming";{" "}
                  </li>
                  <li>
                    Promotes illegal activities or conduct that is abusive,
                    threatening, obscene, defamatory or libelous;{" "}
                  </li>
                  <li>
                    Infringes upon or violates any third party's rights
                    [including, but not limited to, intellectual property
                    rights, rights of privacy (including without limitation
                    unauthorized disclosure of a person's name, email address,
                    physical address or phone number) or rights of publicity];
                  </li>
                  <li>
                    Promotes an illegal or unauthorized copy of another person's
                    copyrighted work (see "copyright complaint" below for
                    instructions on how to lodge a complaint about uploaded
                    copyrighted material), such as providing pirated computer
                    programs or links to them, providing information to
                    circumvent manufacture-installed copy-protect devices, or
                    providing pirated music or links to pirated music files;
                  </li>
                  <li>
                    Contains restricted or password-only access pages, or hidden
                    pages or images (those not linked to or from another
                    accessible page);{" "}
                  </li>
                  <li>
                    Provides material that exploits people in a sexual, violent
                    or otherwise inappropriate manner or solicits personal
                    information from anyone;{" "}
                  </li>
                  <li>
                    Provides instructional information about illegal activities
                    such as making or buying illegal weapons, violating
                    someone's privacy, or providing or creating computer
                    viruses; Contains video, photographs, or images of another
                    person (with a minor or an adult);{" "}
                  </li>
                  <li>
                    Tries to gain unauthorized access or exceeds the scope of
                    authorized access to the Platform or to profiles, blogs,
                    communities, account information, bulletins, friend request,
                    or other areas of the Platform or solicits passwords or
                    personal identifying information for commercial or unlawful
                    purposes from other users;
                  </li>
                  <li>
                    Engages in commercial activities and/or sales without our
                    prior written consent such as contests, sweepstakes, barter,
                    advertising and pyramid schemes, or the buying or selling of
                    products related to the Platform. Throughout these Terms of
                    Use, <span className="fw-medium">Jumuika Online</span>
                    's prior written consent means a communication coming from{" "}
                    <span className="fw-medium">Jumuika Online</span>
                    's Legal Department, specifically in response to your
                    request, and expressly addressing and allowing the activity
                    or conduct for which you seek authorization;{" "}
                  </li>
                  <li>
                    Solicits gambling or engages in any gambling activity which
                    is or could be construed as being illegal; Interferes with
                    another user's use and enjoyment of the Platform or any
                    third party's user and enjoyment of similar services;{" "}
                  </li>
                  <li>
                    xv. Refers to any website or URL that, in our sole
                    discretion, contains material that is inappropriate for the
                    Platform or any other website, contains content that would
                    be prohibited or violates the letter or spirit of these
                    Terms of Use;{" "}
                  </li>
                  <li>
                    Harm minors in any way; Infringes any patent, trademark,
                    copyright or other intellectual property rights or third
                    party's trade secrets or rights of publicity or privacy or
                    shall not be fraudulent or involve the sale of counterfeit
                    or stolen products;{" "}
                  </li>
                  <li>
                    Violates any law for the time being in force; Deceives or
                    misleads the addressee/users about the origin of such
                    messages or communicates any information which is grossly
                    offensive or menacing in nature; Impersonate another person;
                  </li>
                  <li>
                    Contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer resource; or contains any
                    Trojan horses, worms, time bombs, cancel bots, Easter eggs
                    or other computer programming routines that may damage,
                    detrimentally interfere with, diminish value of,
                    surreptitiously intercept or expropriate any system, data or
                    personal information;{" "}
                  </li>
                  <li>
                    Threatens the unity, integrity, defence, security or
                    sovereignty of Africa, friendly relations with foreign
                    states, or public order or causes incitement to the
                    commission of any criminal offence or prevents investigation
                    of any offence or is insulting any other nation;{" "}
                  </li>
                  <li>
                    Is false, inaccurate or misleading; Directly or indirectly,
                    offers, attempts to offer, trades or attempts to trade in
                    any item, the dealing of which is prohibited or restricted
                    in any manner under the provisions of any applicable law,
                    rule, regulation or guideline for the time being in force;
                    or{" "}
                  </li>
                  <li>
                    Creates liability for us or causes us to lose (in whole or
                    in part) the services of our internet service provider or
                    other suppliers.
                    <ul>
                      <li>
                        You shall not use any "deep-link", "page-scrape",
                        "robot", "spider" or other automatic device, program,
                        algorithm or methodology, or any similar or equivalent
                        manual process, to access, acquire, copy or monitor any
                        portion of the Platform or any Content, or in any way
                        reproduce or circumvent the navigational structure or
                        presentation of the Platform or any Content, to obtain
                        or attempt to obtain any materials, documents or
                        information through any means not purposely made
                        available through the Platform. We reserve our right to
                        prohibit any such activity.
                      </li>
                      <li>
                        You shall not attempt to gain unauthorized access to any
                        portion or feature of the Platform, or any other systems
                        or networks connected to the Platform or to any server,
                        computer, network, or to any of the services offered on
                        or through the Platform, by hacking, "password mining"
                        or any other illegitimate means.
                      </li>
                      <li>
                        You shall not probe, scan or test the vulnerability of
                        the Platform or any network connected to the Platform
                        nor breach the security or authentication measures on
                        the Platform or any network connected to the Platform.
                        You may not reverse look-up, trace or seek to trace any
                        information on any other user of or visitor to Platform,
                        or any other Buyer, including any account on the
                        Platform not owned by you, to its source, or exploit the
                        Platform or any service or information made available or
                        offered by or through the Platform, in any way where the
                        purpose is to reveal any information, including but not
                        limited to personal identification or information, other
                        than your own information, as provided for by the
                        Platform.
                      </li>
                      <li>
                        You shall not make any negative, denigrating or
                        defamatory statement(s) or comment(s) about us or the
                        brand name or domain name used by us including the name
                        '<span className="fw-medium">Jumuika Online</span>
                        ', or otherwise engage in any conduct or action that
                        might tarnish the image or reputation, of
                        <span className="fw-medium">Jumuika Online</span> or
                        Merchant on platform or otherwise tarnish or dilute any{" "}
                        <span className="fw-medium">Jumuika Online</span>
                        's trade or service marks, trade name and/or goodwill
                        associated with such trade or service marks, as may be
                        owned or used by us. You agree that you will not take
                        any action that imposes an unreasonable or
                        disproportionately large load on the infrastructure of
                        the Platform or{" "}
                        <span className="fw-medium">Jumuika Online</span>
                        's systems or networks, or any systems or networks
                        connected to{" "}
                        <span className="fw-medium">Jumuika Online</span>.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                You agree not to use any device, software or routine to
                interfere or attempt to interfere with the proper working of the
                Platform or any transaction being conducted on the Platform, or
                with any other person's use of the Platform.
                <ol>
                  <li>
                    You may not forge headers or otherwise manipulate
                    identifiers in order to disguise the origin of any message
                    or transmittal you send to us on or through the Platform or
                    any service offered on or through the Platform. You may not
                    pretend that you are, or that you represent, someone else,
                    or impersonate any other individual or entity.{" "}
                  </li>
                  <li>
                    You may not use the Platform or any content on the Platform
                    for any purpose that is unlawful or prohibited by these
                    Terms of Use, or to solicit the performance of any illegal
                    activity or other activity that infringes the rights of
                    <span className="fw-medium">Jumuika Online</span> and/or
                    others.{" "}
                  </li>
                  <li>
                    In order to allow us to use the information supplied by you,
                    without violating your rights or any laws, you agree to
                    grant us a non-exclusive, worldwide, perpetual, irrevocable,
                    royalty-free, sub-licensable (through multiple tiers) right
                    to exercise the copyright, publicity, database rights or any
                    other rights you have in your Information, in any media now
                    known or not currently known, with respect to your
                    Information. We will only use your information in accordance
                    with these Terms of Use and Privacy Policy applicable to use
                    of the Platform.{" "}
                  </li>
                  <li>
                    From time to time, you shall be responsible for providing
                    information relating to the products or services proposed to
                    be sold by you. In this connection, you undertake that all
                    such information shall be accurate in all respects. You
                    shall not exaggerate or overemphasize the attributes of such
                    products or services so as to mislead other users in any
                    manner.{" "}
                  </li>
                  <li>
                    You shall not engage in advertising to, or solicitation of,
                    other users of the Platform to buy or sell any products or
                    services, including, but not limited to, products or
                    services related to that being displayed on the Platform or
                    related to us. You may not transmit any chain letters or
                    unsolicited commercial or junk email to other users via the
                    Platform. It shall be a violation of these Terms of Use to
                    use any information obtained from the Platform in order to
                    harass, abuse, or harm another person, or in order to
                    contact, advertise to, solicit, or sell to another person
                    other than us without our prior explicit consent. In order
                    to protect our users from such advertising or solicitation,
                    we reserve the right to restrict the number of messages or
                    emails which a user may send to other users in any 24-hour
                    period which we deem appropriate in its sole discretion. You
                    understand that we have the right at all times to disclose
                    any information (including the identity of the persons
                    providing information or materials on the Platform) as
                    necessary to satisfy any law, regulation or valid
                    governmental request. This may include, without limitation,
                    disclosure of the information in connection with
                    investigation of alleged illegal activity or solicitation of
                    illegal activity or in response to a lawful court order or
                    subpoena. In addition, We can (and you hereby expressly
                    authorize us to) disclose any information about you to law
                    enforcement or other government officials, as we, in our
                    sole discretion, believe necessary or appropriate in
                    connection with the investigation and/or resolution of
                    possible crimes, especially those that may involve personal
                    injury.{" "}
                  </li>
                  <li>
                    We reserve the right, but has no obligation, to monitor the
                    materials posted on the Platform.{" "}
                    <span className="fw-medium">Jumuika Online</span> shall have
                    the right to remove or edit any content that in its sole
                    discretion violates, or is alleged to violate, any
                    applicable law or either the spirit or letter of these Terms
                    of Use. Notwithstanding this right, you remain solely
                    responsible for the content of the materials you post on the
                    Platform and in your private messages. Please be advised
                    that such Content posted does not necessarily reflect
                    <span className="fw-medium">Jumuika Online</span> views. In
                    no event shall{" "}
                    <span className="fw-medium">Jumuika Online</span>
                    assume or have any responsibility or liability for any
                    Content posted or for any claims, damages or losses
                    resulting from use of Content and/or appearance of Content
                    on the Platform. You hereby represent and warrant that you
                    have all necessary rights in and to all Content which you
                    provide and all information it contains and that such
                    Content shall not infringe any proprietary or other rights
                    of third parties or contain any libellous, tortious, or
                    otherwise unlawful information.{" "}
                  </li>
                  <li>
                    Your correspondence or business dealings with, or
                    participation in promotions of, advertisers found on or
                    through the Platform, including payment and delivery of
                    related products or services, and any other terms,
                    conditions, warranties or representations associated with
                    such dealings, are solely between you and such advertiser.
                    We shall not be responsible or liable for any loss or damage
                    of any sort incurred as the result of any such dealings or
                    as the result of the presence of such advertisers on the
                    Platform.{" "}
                  </li>
                  <li>
                    It is possible that other users (including unauthorized
                    users or 'hackers') may post or transmit offensive or
                    obscene materials on the Platform and that you may be
                    involuntarily exposed to such offensive and obscene
                    materials. It also is possible for others to obtain personal
                    information about you due to your use of the Platform, and
                    that the recipient may use such information to harass or
                    injure you. We do not approve of such unauthorized uses, but
                    by using the Platform You acknowledge and agree that we are
                    not responsible for the use of any personal information that
                    you publicly disclose or share with others on the Platform.
                    Please carefully select the type of information that you
                    publicly disclose or share with others on the Platform.{" "}
                  </li>
                  <li>
                    <span className="fw-medium">Jumuika Online</span> shall have
                    all the rights to take necessary action and claim damages
                    that may occur due to your involvement/participation in any
                    way on your own or through group/s of people, intentionally
                    or unintentionally in DoS/DDoS (Distributed Denial of
                    Services), hacking, pen testing attempts without our prior
                    consent or a mutual legal agreement.{" "}
                  </li>
                  <li>
                    Account Registration or use of the Platform.
                    <ol>
                      <li>
                        You may access the Platform by registering to create an
                        account ("{" "}
                        <span className="fw-medium">Jumuika Online</span>{" "}
                        Account") and become a member (" Membership"); or (c)
                        you can also register to join by logging into your
                        account with certain third party social networking sites
                        (" SNS") (including, but not limited to, Facebook); each
                        such account, a " Third Party Account", via our
                        Platform, as described below. The Membership is limited
                        for the purpose and are subject to the terms, and
                        strictly not transferable. As part of the functionality
                        of the Platform services, you may link your{" "}
                        <span className="fw-medium">Jumuika Online</span>{" "}
                        Account with Third Party Accounts, by either:
                        <ul>
                          <li>
                            providing your Third Party Account login information
                            to us through the Platform; or{" "}
                          </li>
                          <li>
                            Allowing us to access your Third Party Account, as
                            is permitted under the applicable terms and
                            conditions that govern your use of each Third Party
                            Account.
                          </li>
                        </ul>
                      </li>
                      <li>
                        You represent that you are entitled to disclose your
                        Third Party Account login information to us and/or grant
                        us access to your Third Party Account (including, but
                        not limited to, for use for the purposes described
                        herein), without breach by you of any of the terms and
                        conditions that govern your use of the applicable Third
                        Party Account and without obligating us to pay any fees
                        or making us subject to any usage limitations imposed by
                        such third party service providers.{" "}
                      </li>
                      <li>
                        By granting us access to any Third Party Accounts, you
                        understand that we will access, make available and store
                        (if applicable) any content or information that you have
                        provided to and stored in your Third Party Account ("
                        SNS Content") so that it is available on and through the
                        Platform via your{" "}
                        <span className="fw-medium">Jumuika Online</span>{" "}
                        Account.{" "}
                      </li>
                      <li>
                        Unless otherwise specified in these Terms of Use, all
                        SNS Content, if any, will be considered to be your
                        content for all purposes of these Terms of Use.
                      </li>
                      <li>
                        Depending on the Third Party Accounts, you choose, and
                        subject to the privacy settings that you have set in
                        such Third Party Accounts, personally identifiable
                        information that you post to your Third Party Accounts
                        will be available on and through your{" "}
                        <span className="fw-medium">Jumuika Online</span>
                        Account on the Platform.{" "}
                      </li>
                      <li>
                        Please note that if a Third Party Account or associated
                        service becomes unavailable or our access to such Third
                        Party Account is terminated by the third party service
                        provider, then SNS Content will no longer be available
                        on and through the Platform.{" "}
                      </li>
                      <li>
                        We will create your{" "}
                        <span className="fw-medium">Jumuika Online</span>{" "}
                        Account for your use of the Platform services based upon
                        the personal information you provide to us or that we
                        obtain via an SNS, as described above. You may only have
                        one Jumuika Online Account and not permitted to create
                        multiple accounts. If found, you having multiple
                        accounts,
                        <span className="fw-medium">Jumuika Online</span>{" "}
                        reserves right to suspend such multiple account without
                        being liable for any compensation.{" "}
                      </li>
                      <li>
                        You agree to provide accurate, current and complete
                        information during the registration process and to
                        update such information to keep it accurate, current and
                        complete.{" "}
                      </li>
                      <li>
                        We reserve the right to suspend or terminate your
                        <span className="fw-medium">Jumuika Online</span>{" "}
                        Account and your access to the Services
                        <ol>
                          <li>
                            if any information provided during the registration
                            process or thereafter proves to be inaccurate, not
                            current or incomplete;{" "}
                          </li>
                          <li>
                            if it is believed that your actions may cause legal
                            liability for you, other users or us; and/or{" "}
                          </li>
                          <li>
                            if you are found to be non- compliant with the Terms
                            of Use.
                          </li>
                        </ol>
                      </li>
                      <li>
                        You are responsible for safeguarding your password. You
                        agree that you will not disclose your password to any
                        third party and that you will take sole responsibility
                        for any activities or actions under your{" "}
                        <span className="fw-medium">Jumuika Online</span>
                        Account, whether or not you have authorized such
                        activities or actions. You will immediately notify us of
                        any unauthorized use of your{" "}
                        <span className="fw-medium">Jumuika Online</span>{" "}
                        Account.{" "}
                      </li>
                      <li>
                        Goods and services purchased from the Platform are
                        intended for your personal use and you represent that
                        the same are not for resale or you are not acting as an
                        agent for other parties.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                IV. Bookings and Financial Terms
              </h4>
            </div>
            <ol>
              <li>
                The Platform allows you to place food order bookings and we
                will, subject to the terms and conditions set out herein, enable
                delivery of such order to you.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> do not own,
                sell, resell on its own and/or do not control the Merchants or
                the related services provided in connection thereof. You
                understand that any order that you place shall be subject to the
                terms and conditions set out in these Terms of Use including,
                but not limited to, product availability and delivery location
                serviceability.{" "}
              </li>
              <li>
                As a general rule, all food orders placed on the Platform are
                treated as confirmed.{" "}
              </li>
              <li>
                However, upon your successful completion of booking an order, we
                may call you on the telephone or mobile number provided to
                confirm the details of the order, the price to be paid and the
                estimated delivery time. For this purpose, you will be required
                to share certain information with us, including but not limited
                to
                <ol>
                  <li>your first and last name </li>
                  <li>mobile number; and </li>
                  <li>email address.</li>
                </ol>
                It shall be your sole responsibility to bring any incorrect
                details to our attention.
              </li>
              <li>
                Bookings shall be confirmed in writing (E-mail or fax) as
                enclosed in XX contacts. All bookings will be considered
                confirmed only when the booking is accompanied by a deposit
                valued at 50% of the reserved package, at which time receipt has
                been acknowledged by our bank. The remaining 50% must be paid
                immediately on client arrival. Please see the payment section
                below to see our acceptable forms of payment.
                <ol>
                  <li>Cancellations and Refunds</li>
                  All cancellations of any reservation must be in writing,
                  either by e-mail or fax and will only be effective upon
                  receipt and acknowledgement. The following cancellation policy
                  is:
                  <ul>
                    <li>
                      More than 48 hours notice prior to your reservation, you
                      receive a 100% refund of any advance deposited whatsoever.
                    </li>
                    <li>
                      No refunds are given for the following reasons:
                      <ul>
                        <li>
                          Presence of circumstances beyond company control which
                          requires alternative arrangements to be made in order
                          to ensure the safety or further participation and
                          enjoyment of your reservation, under presence and
                          circumstance.
                        </li>
                        <li>
                          Lack of your appearance for any accommodation,
                          service, activity or the reservation segment without
                          prior notice.
                        </li>
                        <li>Premature contract termination.</li>
                      </ul>
                    </li>
                  </ul>
                  <li>Payment</li>
                  Non-residents of Tanzania are required by law to pay in
                  foreign convertible currencies. We do not encourage using
                  credit card payments at this time, since we have recently had
                  problems with fraud. We do not consider it safe and therefore
                  only accept payment by bank transfer, mobile money
                  transactions and or cash.
                  <li>
                    Risk
                    <ul>
                      <li>
                        The Company and its owner, director, management staff,
                        and employees shall not be held responsible for any
                        injury to persons nor for loss or damage to personal
                        property, however may they be caused during being under
                        our premises as per reservations made.{" "}
                      </li>
                      <li>
                        It is your sole responsibility to obtain appropriate
                        medical advice as to medication, immunization, and
                        whether or not you are fit enough to undertake the trip
                        prior to departure. The company is not liable for
                        illness, injury, or death sustained while visiting the
                        properties owned and managed by the company.
                      </li>
                    </ul>
                  </li>
                  <li>Consent</li>
                  The payment of the deposit or any other partial payment for a
                  reservation constitutes consent to all provisions of the Terms
                  and Conditions. The Terms under which you agree to make the
                  reservation cannot be changed or amended unless this is done
                  in writing and signed by an authorized staff member of the
                  company.
                </ol>
              </li>
              <li>
                Vouchers Terms and Conditions
                <ol>
                  <li>
                    All vouchers issued by us are subjected to a set of terms of
                    conditions, which we reserve the right to change without
                    prior notice in the best interests of the company.{" "}
                  </li>
                  <li>
                    All vouchers are valid for the purchase of food and
                    beverages as per Terms with our Merchants{" "}
                  </li>
                  <li>
                    All vouchers are limited to one voucher per transaction
                    only, and are not valid for usage with other types of
                    vouchers, discounts, promotions or offers unless otherwise
                    stated.{" "}
                  </li>
                  <li>
                    Vouchers are not exchangeable for cash and any photocopied
                    voucher is not acceptable.{" "}
                  </li>
                  <li>
                    Amount of voucher must be fully utilized when making
                    payment. Any unused amount will not be refunded.{" "}
                  </li>
                  <li>
                    The Voucher points cannot be awarded or redeemed with the
                    usage of vouchers and at no circumstance will an extension
                    be given to an expired voucher/s.{" "}
                  </li>
                  <li>
                    <span className="fw-medium">Jumuika Online</span> will not
                    be responsible for replacing any lost, damaged or stolen
                    vouchers and we reserve the right to amend validity date of
                    vouchers or reject vouchers in order to protect the
                    interests of the company or under circumstances of abuse.{" "}
                  </li>
                  <li>
                    Any Voucher holder must be 18 or over at the time of check
                    in to its avail. The voucher cannot be used after 11:59pm.
                  </li>
                  <li>
                    Only one voucher is accepted per guest, vouchers cannot be
                    transferred to another.{" "}
                  </li>
                  <li>
                    If any coupon code is not applicable which took from Third
                    Party website and or Blog,{" "}
                    <span className="fw-medium">Jumuika Online</span> will
                    solely NOT responsible whatsoever.{" "}
                  </li>
                  <li>
                    All decisions made by the management are final, management
                    retain the right to cancel this promotion at any time
                    without giving reason and without notifying customers in
                    advance.{" "}
                  </li>
                  <li>
                    The vouchers cannot be exchanged for cash or credit in the
                    company premises or whatsoever.{" "}
                  </li>
                  <li>
                    The vouchers must be validated. (Stamped or approved) by the
                    management, if they are not then they will not be accepted
                    by the <span className="fw-medium">Jumuika Online</span>{" "}
                    staff.{" "}
                  </li>
                  <li>
                    The voucher entitles the bearer as per stated on the voucher
                    and cannot be entitled otherwise. It is not extended
                    otherwise.{" "}
                  </li>
                  <li>
                    For further enquiries, please email info@jumuika.online
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">V. Refer & Earn Terms</h4>
            </div>
            <ol>
              <li>i. Your friend must not have registered with us before. </li>
              <li>
                The phone number which your friend will use to sign up shouldn’t
                have been used earlier for{" "}
                <span className="fw-medium">Jumuika Online</span> transaction.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> App should not
                be installed using App runtime for Chrome, emulators or
                simulators.{" "}
              </li>
              <li>
                It is only for the users based in Tanzania. The mobile number
                provided to receive OTP should be a Tanzanian mobile number.{" "}
              </li>
              <li>
                If your friend uses someone else’s referral code, the person
                whose referral code is used will get the benefits even if you
                had referred him/her first.{" "}
              </li>
              <li>
                Points earned from Refer & Earn will be credited to Jumuika
                Online Wallet as “My Points”.{" "}
              </li>
              <li>
                Points have validity. Please check this information in the
                <span className="fw-medium">Jumuika Online</span> Wallet
                section. If unutilized, points will cease to exist & will not be
                renewed.{" "}
              </li>
              <li>Points can neither be exchanged nor be transferred. </li>
              <li>Only registered users can use My Points. </li>
              <li>
                The maximum referral bonus that can be earned by a referrer is
                capped and may differ for each referral offer.{" "}
              </li>
              <li>
                You cannot add funds directly to your{" "}
                <span className="fw-medium">Jumuika Online</span> wallet
                account. Only referral amount will get added to the wallet.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> reserves all
                rights to change the amounts conferred under Refer and Earn
                program at any point in time.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> may suspend or
                terminate the Refer and Earn program or any user’s ability to
                participate in the program at any time for any reason at their
                discretion. Points earned as a result of fraudulent activities
                will be revoked and deemed invalid.
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> reserves the
                right to amend these terms and conditions at any time without
                any prior notice. Modifications of these terms will be effective
                from the time they are updated in the Terms and Conditions
                section.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                VI. Communications on purchase, payment and deliveries.
              </h4>
            </div>
            <ol>
              <li>
                In addition to the foregoing, we may also contact you by phone
                and / or email to inform and confirm any change in the order,
                due to availability or unavailability or change in price of the
                order as informed by the Merchant. Please note change or
                confirmation of the order shall be treated as final. It is
                clarified that <span className="fw-medium">Jumuika Online</span>{" "}
                reserves the right to not to process your order in the event you
                are unavailable on the phone at the time we call you for
                confirming the order and such event the provisions of the
                cancellation and refund policy below shall be applicable.{" "}
              </li>
              <li>
                All payments made against the purchases/services on the Platform
                by you shall be compulsorily in TZS acceptable in the Republic
                of Tanzania. The Platform will not facilitate transactions with
                respect to any other form of currency with respect to the
                purchases made on Platform. You can pay by
                <ol>
                  <li>credit card or debit card or net banking; </li>
                  <li>credit or debit card or cash at the time of delivery.</li>
                </ol>
                You understand, accept and agree that the payment facility
                provided by <span className="fw-medium">Jumuika Online</span> is
                neither a banking nor financial service but is merely a
                facilitator providing an electronic, automated online electronic
                payment, receiving payment on delivery, collection and
                remittance facility for the transactions on the Platform using
                the existing authorized banking infrastructure and VISA/debit
                card payment gateway networks. Further, by providing payment
                facility, <span className="fw-medium">Jumuika Online</span>
                is neither acting as trustees nor acting in a fiduciary capacity
                with respect to the transaction or the transaction price.{" "}
              </li>
              <li>
                You acknowledge and agree that we act as the Merchant's payment
                agent for the limited purpose of accepting payments from you on
                behalf of the Merchant. Upon your payment of amounts to us,
                which are due to the Merchant, your payment obligation to the
                Merchant for such amounts is completed, and we are responsible
                for remitting such amounts, to the Merchant. You shall not,
                under any circumstances whatsoever, make any payment directly to
                the Merchant for Order bookings made using the Platform.{" "}
              </li>
              <li>
                You agree to pay us for the total amount for the order placed by
                you on the Platform. We will collect the total amount in
                accordance with the terms and conditions of these Terms of Use
                and the pricing terms set forth in the applicable menu listing
                for the particular Merchant. Please note that we cannot control
                any amount that may be charged to you by your bank related to
                our collection of the total amount, and we disclaim all
                liability in this regard.{" "}
              </li>
              <li>
                In connection with your order, you will be asked to provide
                customary billing information such as name, billing address and
                credit card information either to us or our third party payment
                processor. You hereby authorize the collection of such amounts
                by charging the credit/debit card provided as part of requesting
                the booking, either directly by us or indirectly, via a third
                party online payment processor or by one of the payment methods
                described on the Platform. If you are directed to our
                third-party payment processor, you may be subject to terms and
                conditions governing use of that third party's service and that
                third party's personal information collection practices. Please
                review such terms and conditions and privacy policy before using
                the Platform services. Once you have confirmed booking
                transaction is complete.a confirmation email will be shared to
                summarize your confirmed booking.{" "}
              </li>
              <li>
                The final tax bill will be issued by the Merchant to the Buyer
                along with the order and{" "}
                <span className="fw-medium">Jumuika Online</span> is merely
                collecting the payment on behalf of such Merchant. All
                applicable taxes and levies, the rates thereof and the manner of
                applicability of such taxes on the bill are being charged and
                determined by the Merchant.{" "}
                <span className="fw-medium">Jumuika Online</span> holds no
                responsibility for the legal correctness/validity of the levy of
                such taxes. The sole responsibility for any legal issue arising
                on the taxes shall reside with the Merchant.
              </li>
              <li>
                The prices reflected on the Platform, including packaging or
                handling charges, are determined solely by the Merchant and are
                listed based on Merchant's information. Very rarely, prices may
                change at the time of placing order due to Merchant changing the
                menu price without due intimation and such change of price are
                at the sole discretion of the Merchant attributing to various
                factors beyond control. Disclaimer: Prices on any product(s) as
                is reflected on the Platform may due to some technical issue,
                typographical error or product information supplied by Merchant
                be incorrectly reflected and in such an event Merchant may
                cancel such your order(s).
                <ul>
                  <li>
                    The Merchant shall be solely responsible for any
                    warrantee/guarantee of the food products sold to the Buyers
                    and in no event shall be the responsibility of Jumuika
                    Online.{" "}
                  </li>
                  <li>
                    The transaction is bilateral between the Merchant and Buyer
                    and therefore,{" "}
                    <span className="fw-medium">Jumuika Online</span> is not
                    liable to charge or deposit any taxes applicable on such
                    transaction.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">VII. Cancellations and Refunds.</h4>
            </div>
            <ol>
              <li>
                i.As a general rule you shall not be entitled to cancel your
                order once you have received confirmation of the same. If you
                cancel your order after it has been confirmed,{" "}
                <span className="fw-medium">Jumuika Online</span>
                shall have a right to charge you cancellation fee of a minimum
                of 50% upto the order value (inclusive of applicable taxes),
                with a right to either not to refund the order value or recover
                from your subsequent order, the complete/ deficit cancellation
                fee, as applicable, to compensate our restaurant and delivery
                partners. <span className="fw-medium">Jumuika Online</span>{" "}
                shall also have right to charge you cancellation fee for the
                orders cancelled by{" "}
                <span className="fw-medium">Jumuika Online</span>
                (iii) of this cancellation and refunds policy. In case of
                cancellations for the reasons attributable to{" "}
                <span className="fw-medium">Jumuika Online</span> or its
                restaurant and delivery partners,{" "}
                <span className="fw-medium">Jumuika Online</span> shall not
                charge you any cancellation fee.{" "}
              </li>
              <li>
                However, in the unlikely event of an item on your order being
                unavailable, we will contact you on the phone number provided to
                us at the time of placing the order and inform you of such
                unavailability. In such an event you will be entitled to cancel
                the entire order and shall be entitled to a refund in accordance
                with our refund policy.{" "}
              </li>
              <li>
                We reserve the sole right to cancel your order in the following
                circumstance:
                <ul>
                  <li>
                    In the event of the designated address falls outside the
                    delivery zone offered by us;{" "}
                  </li>
                  <li>
                    Failure to contact you by phone or email at the time of
                    confirming the order booking;{" "}
                  </li>
                  <li>
                    Failure to deliver your order due to lack of information,
                    direction or authorization from you at the time of delivery;
                    or{" "}
                  </li>
                  <li>
                    Unavailability of all the items ordered by you at the time
                    of booking the order.
                  </li>
                </ul>
                You shall be entitled to a refund only if you pre-pay for your
                order at the time of placing your order on the Platform and only
                in the event of any of the following circumstances:
                <ul>
                  <li>
                    Your order packaging has been tampered or damaged at the
                    time of delivery;{" "}
                  </li>
                  <li>
                    Us cancelling your order due to (A) your delivery location
                    following outside our designated delivery zones; (B) failure
                    to contact you by phone or email at the time of confirming
                    the order booking; or you cancelling the order at the time
                    of confirmation due to unavailability of the items you
                    ordered for at the time of booking.{" "}
                  </li>
                </ul>
              </li>
              <li>
                Our decision on refunds shall be at our sole discretion and
                shall be final and binding.{" "}
              </li>
              <li>
                All refund amounts shall be credited to your account within 3-4
                business days in accordance with the terms that may be
                stipulated by the bank which has issued the VISA / debit card.
              </li>
              <li>
                In case of payment at the time of delivery, you will not be
                required to pay for:
                <ul>
                  <li>
                    Orders where the packaging has been tampered or damaged by
                    us;
                  </li>
                  <li>Wrong order being delivered; or </li>
                  <li>
                    Items missing from your order at the time of delivery.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">VIII. Terms of service</h4>
            </div>
            You agree and acknowledge that we shall not be responsible for:
            <ul>
              <li>
                The services or goods provided by the Merchant including, but
                not limited, serving of food orders suiting your requirements
                and needs;{" "}
              </li>
              <li>
                The Merchant's services or goods not being up to your
                expectations or leading to any loss, harm or damage to you;{" "}
              </li>
              <li>
                The availability or unavailability of certain items on the menu;
                or{" "}
              </li>
              <li>The Merchant serving the incorrect orders.</li>
            </ul>
            The details of the menu and price list available on the Platform are
            based on the information provided by the Merchants and we shall not
            be responsible for any change or cancellation or unavailability.
            <br />
            You may not be able to avail our Services if your delivery location
            is outside our current scope of Service. We will keep you informed
            of the same at the time of confirming your order booking.
            <br />
            You understand that delivery periods quoted to you at the time of
            confirming the order is an approximate estimate and may vary. We
            will not be responsible for any delay in the delivery of your order.
            <br />
            Your order will be only delivered to the address designated by you
            at the time of placing the order on the Platform. We reserve the
            right to cancel the order, in our sole discretion, in the event of
            any change to the place of delivery and you shall not be entitled to
            any refund for the same. Delivery in the event of change of the
            delivery location shall be at our sole discretion.
            <br />
            You shall undertake to provide adequate directions, information and
            authorisations to accept delivery. In the event of any failure to
            accept delivery, failure to deliver within the estimated time due to
            your failure to provide appropriate instructions, or authorizations,
            then such goods shall be deemed to have been delivered to you and
            all risk and responsibility in relation to such goods shall pass to
            you and you shall not be entitled to any refund for the same. Our
            decision in relation to this shall be final and binding.
            <br />
            You understand that our liability ends once your order has been
            delivered to you.
            <br />
            You agree and acknowledge that neither{" "}
            <span className="fw-medium">Jumuika Online</span> nor the Merchant
            shall be liable in the event of you failing to adhere to the Terms
            of Use.
            <br />
            You might be required to provide your VISA or debit card details to
            the approved payment gateways while making the payment. In this
            regard, you agree to provide correct and accurate VISA/ debit card
            details to the approved payment gateways for availing the Services.
            You shall not use the VISA/ debit card which is not lawfully owned
            by you, i.e. in any transaction, you must use your own VISA/ debit
            card. The information provided by you will not be utilized or shared
            with any third party unless required in relation to fraud
            verifications or by law, regulation or court order. You will be
            solely responsible for the security and confidentiality of your
            credit/ debit card details. We expressly disclaim all liabilities
            that may arise as a consequence of any unauthorized use of your
            VISA/debit card.
            <br />
            We do not offer any refunds against goods already purchased from the
            Platform unless an error that is directly attributable to us has
            occurred during the purchase of such product or services.
            <br />
            We constantly strive to provide you with accurate information on the
            Platform. However, in the event of an error, we may, in our sole
            discretion, contact you with further instructions.
            <br />
            If you use the Platform, you do the same at your own risk.
            <br />
            You agree that the Services shall be provided by{" "}
            <span className="fw-medium">Jumuika Online</span> only during the
            working hours of the relevant Merchants.
            <br />
            Services provided:
            <ul>
              <li>
                You confirm that we shall not be responsible for any deficiency
                in payment of consideration payable towards the goods purchased
                from the Services.{" "}
              </li>
              <li>
                Each purchase on the Service shall contain necessary
                instructions to redeem the Services. The terms of the Services
                shall be governed by these Terms of Use and any other terms as
                set out in such document confirm such sale of Service. You shall
                not be entitled to receive any credit, refund or cash back for
                the value of the goods sold if you fail to redeem the goods
                within the expiry date or in accordance with the terms therein.
              </li>
            </ul>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">IX. Endorsement</h4>
            </div>
            <ul>
              <li>
                We do not endorse any Merchant. In addition, although these
                Terms of Use require you to provide accurate information, we do
                not attempt to confirm, and do not confirm if it is purported
                identity. We will not be responsible for any damage or harm
                resulting from your interactions with other Members.{" "}
              </li>
              <li>
                By using the Services, you agree that any legal remedy or
                liability that you seek to obtain for actions or omissions of
                other Members or other third parties will be limited to a claim
                against the particular Members or other third parties who caused
                you harm and you agree not to attempt to impose liability on, or
                seek any legal remedy from{" "}
                <span className="fw-medium">Jumuika Online</span> with respect
                to such actions or omissions.
              </li>
            </ul>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">X. General terms of use</h4>
            </div>
            <ol>
              <li>
                i. Including minors, un-discharged insolvents etc. are not
                eligible to use the Platform. Only individuals who are 18 years
                of age or older may use the Platform and avail Services. If you
                are under 18 years of age and you wish to download, install,
                access or use the Platform, your parents or legal guardian must
                acknowledge and agree to the Terms of Use and Privacy Policy.
                Should your parents or legal guardian fail to agree or
                acknowledge the Terms of Use and{" "}
                <span className="fw-medium">
                  <span className="fw-medium">Jumuika Online</span> Policies
                </span>
                , you shall immediately discontinue its use.{" "}
                <span className="fw-medium">Jumuika Online</span> reserves the
                right to terminate your Membership and / or deny access to the
                platform if it is brought{" "}
                <span className="fw-medium">Jumuika Online</span>
                's notice that you are under the age of 18 years.{" "}
              </li>
              <li>
                If you choose to use the Platform, it shall be your
                responsibility to treat your user identification code, password
                and any other piece of information that we may provide, as part
                of our security procedures, as confidential and not disclose the
                same to any person or entity other than{" "}
                <span className="fw-medium">Jumuika Online</span>. We shall at
                all times and at our sole discretion reserve the right to
                disable any user identification code or password if you have
                failed to comply with any of the provisions of these Terms of
                Use.{" "}
              </li>
              <li>
                As we are providing services in the selected cities in Tanzania,
                we have complied with applicable laws of Tanzania in making the
                Platform and its content available to you. In the event the
                Platform is accessed from outside Tanzania or outside our
                delivery zones, it shall be entirely at your risk. We make no
                representation that the Platform and its contents are available
                or otherwise suitable for use outside select cities. If you
                choose to access or use the Platform from or in locations
                outside select cities, you do so at your own risk and shall be
                responsible for the consequences and ensuring compliance of
                applicable laws, regulations, by laws, licenses, registrations,
                permits, authorisations, rules and guidelines.{" "}
              </li>
              <li>
                You shall at all times be responsible for the use of the
                Services through your computer or mobile device and for bringing
                these Terms of Use and{" "}
                <span className="fw-medium">
                  <span className="fw-medium">Jumuika Online</span> Policies
                </span>{" "}
                to the attention of all such persons accessing the Platform on
                your computer or mobile device.{" "}
              </li>
              <li>
                You understand and agree that the use of the Services does not
                include the provision of a computer or mobile device or other
                necessary equipment to access it. You also understand and
                acknowledge that the use of the Platform requires internet
                connectivity and telecommunication links. You shall bear the
                costs incurred to access and use the Platform and avail the
                Services. <span className="fw-medium">Jumuika Online</span>{" "}
                shall not, under any circumstances whatsoever, be responsible or
                liable for such costs.
                <br />
                <br />
                You agree and grant permission to{" "}
                <span className="fw-medium">Jumuika Online</span> to receive
                promotional SMS and e-mails from{" "}
                <span className="fw-medium">Jumuika Online</span> or allied
                partners. In case you wish to opt out of receiving promotional
                SMS or email please send a mail to info@jumuika.online
              </li>
              <li>
                By using the Platform you represent and warrant that:
                <ul>
                  <li>
                    All registration information you submit is truthful, lawful
                    and accurate and that you agree to maintain the accuracy of
                    such information.{" "}
                  </li>
                  <li>
                    Your use of the Platform shall be solely for your personal
                    use and you shall not authorize others to use your account,
                    including your profile or email address and that you are
                    solely responsible for all content published or displayed
                    through your account, including any email messages, and your
                    interactions with other users and you shall abide by all
                    applicable local, state, national laws, treaties and
                    regulations, including those related to data privacy,
                    international communications and the transmission of
                    technical or personal data.{" "}
                  </li>
                  <li>
                    You will not submit, post, upload, distribute, or otherwise
                    make available or transmit any content that:
                    <ol>
                      <li>
                        is defamatory, abusive, harassing, insulting,
                        threatening, or that could be deemed to be stalking or
                        constitute an invasion of a right of privacy of another
                        person;{" "}
                      </li>
                      <li>
                        is bigoted, hateful, or racially or otherwise offensive;
                      </li>
                      <li>
                        is violent, vulgar, obscene, pornographic or otherwise
                        sexually explicit;{" "}
                      </li>
                      <li>
                        is illegal or encourages or advocates illegal activity
                        or the discussion of illegal activities with the intent
                        to commit them.{" "}
                      </li>
                    </ol>
                  </li>
                  <li>
                    All necessary licenses, consents, permissions and rights are
                    owned by you and there is no need for any payment or
                    permission or authorization required from any other party or
                    entity to use, distribute or otherwise exploit in all
                    manners permitted by these Terms of Use and Privacy Policy,
                    all trademarks, copyrights, patents, trade secrets, privacy
                    and publicity rights and / or other proprietary rights
                    contained in any content that you submit, post, upload,
                    distribute or otherwise transmit or make available.{" "}
                  </li>
                  <li>
                    You will not
                    <ol>
                      <li>
                        use any services provided by the Platform for commercial
                        purposes of any kind, or{" "}
                      </li>
                      <li>
                        advertise or sell any products, services or otherwise
                        (whether or not for profit), or solicit others
                        (including, without limitation, solicitations for
                        contributions or donations) or use any public forum for
                        commercial purposes of any kind. In the event you want
                        to advertise your product or service contact
                        info@jumuika.online.
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                vii. You will not use the Platform in any way that is unlawful,
                or harms us or any other person or entity, as determined in our
                sole discretion.{" "}
              </li>
              <li>
                You will not post, submit, upload, distribute, or otherwise
                transmit or make available any software or other computer files
                that contain a virus or other harmful component, or otherwise
                impair or damage the Platform or any connected network, or
                otherwise interfere with any person or entity's use or enjoyment
                of the Platform.{" "}
              </li>
              <li>
                You will not use another person's username, password or other
                account information, or another person's name, likeness, voice,
                image or photograph or impersonate any person or entity or
                misrepresent your identity or affiliation with any person or
                entity.{" "}
              </li>
              <li>
                You will not engage in any form of antisocial, disrupting, or
                destructive acts, including "flaming," "spamming," "flooding,"
                "trolling," and "griefing" as those terms are commonly
                understood and used on the Internet.{" "}
              </li>
              <li>
                You will not delete or modify any content of the Platform,
                including but not limited to, legal notices, disclaimers or
                proprietary notices such as copyright or trademark symbols,
                logos, that you do not own or have express permission to modify.{" "}
              </li>
              <li>
                You will not post or contribute any information or data that may
                be obscene, indecent, pornographic, vulgar, profane, racist,
                sexist, discriminatory, offensive, derogatory, harmful,
                harassing, threatening, embarrassing, malicious, abusive,
                hateful, menacing, defamatory, untrue or political or contrary
                to our interest.{" "}
              </li>
              <li>
                You shall not access the Platform without authority or use the
                Platform in a manner that damages, interferes or disrupts:
                <ul>
                  <li>any part of the Platform or the Platform software; or</li>
                  <li>
                    any equipment or any network on which the Platform is stored
                    or any equipment of any third party
                  </li>
                </ul>
              </li>
              <li>
                You release and fully indemnify{" "}
                <span className="fw-medium">Jumuika Online</span> and/or any of
                its officers and representatives from any cost, damage,
                liability or other consequence of any of the actions of the
                Users of the Platform and specifically waive any claims that you
                may have in this behalf under any applicable laws of Tanzania.
                Notwithstanding its reasonable efforts in that behalf, Jumuika
                Online cannot take responsibility or control the information
                provided by other Users which is made available on the Platform.
                You may find other User's information to be offensive, harmful,
                inconsistent, inaccurate, or deceptive. Please use caution and
                practice safe trading when using the Platform.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                XI. Access to the Platform, Accuracy and security
              </h4>
            </div>
            <ol>
              <li>
                We endeavor to make the Platform available to during Merchant
                working hours. However, we do not represent that access to the
                Platform will be uninterrupted, timely, error free, free of
                viruses or other harmful components or that such defects will be
                corrected.{" "}
              </li>
              <li>
                We do not warrant that the Platform will be compatible with all
                hardware and software which you may use. We shall not be liable
                for damage to, or viruses or other code that may affect, any
                equipment (including but not limited to your mobile device),
                software, data or other property as a result of your download,
                installation, access to or use of the Platform or your obtaining
                any material from, or as a result of using, the Platform. We
                shall also not be liable for the actions of third parties.{" "}
              </li>
              <li>
                We do not represent or warranty that the information available
                on the Platform will be correct, accurate or otherwise reliable.
              </li>
              <li>
                We reserve the right to suspend or withdraw access to the
                Platform to you personally, or to all users temporarily or
                permanently at any time without notice. We may any time at our
                sole discretion reinstate suspended users. A suspended User may
                not register or attempt to register with us or use the Platform
                in any manner whatsoever until such time that such user is
                reinstated by us.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                XII. Relationship with operators if the Platform is accessed on
                mobile devices
              </h4>
            </div>
            <ol>
              <li>
                In the event the Platform is accessed on a mobile device, it is
                not associated, affiliated, sponsored, endorsed or in any way
                linked to any platform operator, including, without limitation,
                Apple, Google, Android or RIM Blackberry (each being an "
                Operator").{" "}
              </li>
              <li>
                Your download, installation, access to or use of the Platform is
                also bound by the terms and conditions of the Operator.{" "}
              </li>
              <li>
                You and <span className="fw-medium">Jumuika Online</span>{" "}
                acknowledge that these Terms of Use are concluded between you
                and <span className="fw-medium">Jumuika Online</span> only, and
                not with an Operator, and{" "}
                <span className="fw-medium">Jumuika Online</span>, not those
                Operators, are solely responsible for the Platform and the
                content thereof to the extent specified in these Terms of Use.
              </li>
              <li>
                The license granted to you for the Platform is limited to a
                non-transferable license to use the Platform on a mobile device
                that you own or control and as permitted by these Terms of Use.
              </li>
              <li>
                We are solely responsible for providing any maintenance and
                support services with respect to the Platform as required under
                applicable law. You and we acknowledge that an Operator has no
                obligation whatsoever to furnish any maintenance and support
                services with respect to the Platform.{" "}
              </li>
              <li>
                You and <span className="fw-medium">Jumuika Online</span>{" "}
                acknowledge that{" "}
                <span className="fw-medium">Jumuika Online</span>, not the
                relevant Operator, are responsible for addressing any claims of
                you or any third party relating to the Platform or your
                possession and/or use of the Platform, including, but not
                limited to:
                <ol>
                  <li>
                    any claim that the Platform fails to conform to any
                    applicable legal or regulatory requirement; and
                  </li>
                  <li>
                    claims arising under consumer protection or similar
                    legislation.
                  </li>
                </ol>
              </li>
              <li>
                You and <span className="fw-medium">Jumuika Online</span>{" "}
                acknowledge that, in the event of any third party claim that the
                Platform or your possession and use of the Platform infringes
                that third party's intellectual property rights,{" "}
                <span className="fw-medium">Jumuika Online</span>, not the
                relevant Operator, will be solely responsible for the
                investigation, defence, settlement and discharge of any such
                intellectual property infringement claim.{" "}
              </li>
              <li>
                You must comply with any applicable third party terms of
                agreement when using the Platform (e.g. you must ensure that
                your use of the Platform is not in violation of your mobile
                device agreement or any wireless data service agreement).{" "}
              </li>
              <li>
                You and <span className="fw-medium">Jumuika Online</span>{" "}
                acknowledge and agree that the relevant Operator, and that
                Operator's subsidiaries, are third party beneficiaries of these
                Terms of Use, and that, upon your acceptance of these Terms of
                Use, that Operator will have the right (and will be deemed to
                have accepted the right) to enforce these Terms of Use against
                you as a third party beneficiary thereof.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">XII. Disclaimers.</h4>
            </div>
            <ol>
              <li>
                The Platform maybe be under constant upgrades and some of its
                functions and features may not be fully operational{" "}
              </li>
              <li>
                Due to the vagaries that can occur in the electronic
                distribution of the information and due to the limitation
                inherent in providing information obtained from multiple
                sources, there may be delays, omissions, and or inaccuracies in
                the content provided on the Platform or delays or errors in
                functionality of the Platform. As a result, we do not represent
                that the information posted is correct in every case.{" "}
              </li>
              <li>
                We expressly disclaim all liabilities that may arise as a
                consequence of any unauthorised use of Credit/debit cards.{" "}
              </li>
              <li>
                You acknowledged that third party services are available on the
                platform. We may have formed partnership or alliances with some
                of these third parties from time to time in order to facilitate
                the provision of certain services to you. However, you
                acknowledge and agree that at no time are we making any
                representation or warranty regarding any third part’s services
                nor will we be liable to you or any other third party for any
                consequences or claims arising from or in connection with such
                third party including, and not limited to, any liability or
                responsibility for, death, injury or impairment experienced by
                you or any third party. You hereby disclaim and waive any rights
                and claims you may have against us with respect to third
                party’s/merchants services.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> disclaims and
                all liability that may arise due to any violation of the food
                safety and standards and applicable rules and regulations made
                thereunder and such liability shall be attributable to the
                merchant.{" "}
              </li>
              <li>
                While the materials provided in the Platform were prepared to
                provide accurate information regarding the subject discussed,
                the information contained in these materials is being made
                available with the understanding that we make no guarantees,
                representations or guarantees whatsoever, whether expressed or
                implied, with respect to professional qualifications, expertise,
                quality of work or other information herein. Further, we do not,
                in any way, endorse any service offered or described herein. In
                no event shall we be liable to you or any third party for any
                decision made or action taken in reliance on such information.{" "}
              </li>
              <li>
                The information provided hereunder is provided “as is|”. We and
                or / our employees make no warranty or representation regarding
                the timeliness, content, sequence, accuracy, effectiveness or
                completeness of any information or data furnished hereunder or
                the information or data provided hereunder maybe relied upon.
                Multiple responses may usually be made available from different
                sources and it is left to the judgement of users based on their
                specific circumstances to use, adapt, modify or alter
                suggestions or use them in conjunction with any other sources
                they may have, thereby absolving us as well as our consultants,
                business associates, affiliates, business partners and employees
                from any kind of professional liability.{" "}
              </li>
              <li>
                We shall not be liable to you or anyone else for any loses or
                injury arising out of or relating to the information provided on
                the Platform. In no event will we or our employees, affiliates,
                authors, or agents be liable to you or any third party for any
                decision made or action taken by your reliance on the content
                contained herein.
              </li>
              <li>
                In no event will{" "}
                <span className="fw-medium">Jumuika Online</span> be reliable
                for any damages (including, without limitation, direct or
                indirect, incidental, special, consequential or exemplary
                damages, damages arising from personal injury /wrongful death,
                and damages resulting from any lost profits, lost data or
                business interruption), resulting from any services provided by
                the third party or merchant accessed through the Platform,
                whether based on warranty, contract, tort, or any other legal
                theory and whether or not we are advised of the possibility of
                such damages.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">XIII. Intellectual property</h4>
            </div>
            <ol>
              <li>
                We are either the owner of intellectual property rights or have
                the non-exclusive, worldwide, perpetual, irrevocable, royalty
                free, sub-licensable (through multiple tiers) right to exercise
                the intellectual property, in the Platform, and in the material
                published on it.{" "}
              </li>
              <li>
                You may print off one copy, and may download extracts, of any
                page(s) from the Platform for your personal reference and you
                may draw the attention of others within your organisation to
                material available on the Platform.{" "}
              </li>
              <li>
                You must not modify the paper or digital copies of any materials
                you have printed off or downloaded in any way, and you must not
                use any illustrations, photographs, video or audio sequences or
                any graphics separately from any accompanying text.{" "}
              </li>
              <li>
                You must not use any part of the materials on the Platform for
                commercial purposes without obtaining a licence to do so from us
                or our licensors.{" "}
              </li>
              <li>
                If you print off, copy or download any part of the Platform in
                breach of these Terms of Use, your right to use the Platform
                will cease immediately and you must, at our option, return or
                destroy any copies of the materials you have made.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                XIV. Treatment of information provided by you
              </h4>
            </div>
            We process information provided by you to{" "}
            <span className="fw-medium">Jumuika Online</span> in accordance with
            our Privacy Policy.
          </div>
          <div>
            <div>
              <h4 className="Simple_header">XV. Third Party Content</h4>
            </div>
            <ol>
              <li>
                We cannot and will not assure that other users are or will be
                complying with the foregoing rules or any other provisions of
                these Terms of Use, and, as between you and{" "}
                <span className="fw-medium">Jumuika Online</span>, you hereby
                assume all risk of harm or injury resulting from any such lack
                of compliance.{" "}
              </li>
              <li>
                You acknowledge that when you access a link that leaves the
                Platform, the site you will enter into is not controlled by
                <span className="fw-medium">Jumuika Online</span> and different
                terms of use and privacy policy may apply. By assessing links to
                other sites, you acknowledge that
                <span className="fw-medium">Jumuika Online</span> is not
                responsible for those sites. Jumuika Online reserve the right to
                disable links to and / or from third-party sites to the
                Platform, although we are under no obligation to do so.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">XVI. Third Party Content</h4>
            </div>
            If any of these Terms of Use should be determined to be illegal,
            invalid or otherwise unenforceable by reason of the laws of any
            state or country in which these Terms of Use are intended to be
            effective, then to the extent and within the jurisdiction where that
            term is illegal, invalid or unenforceable, it shall be severed and
            deleted and the remaining Terms of Use shall survive, remain in full
            force and effect and continue to be binding and enforceable.
            <ol>
              <li>
                Non-assignment You shall not assign or transfer or purport to
                assign or transfer the contract between you and{" "}
                <span className="fw-medium">Jumuika Online</span>
                to any other third and or any otherperson.{" "}
              </li>
              <li>
                Governing law and dispute resolution These Terms of Use are
                governed by the laws of Tanzania. Any action, suit, or other
                legal proceeding, which is commenced to resolve any matter
                arising under or relating to this Platform, shall be subject to
                the jurisdiction of the courts at Dar es salaam Tanzania{" "}
              </li>
              <li>
                IP Notice and Take Down Policy
                <ul>
                  <li>
                    <span className="fw-medium">Jumuika Online</span> has put in
                    place IP Notice and Take Down Policy (" Take Down Policy")
                    so that intellectual property owners could easily report
                    listings that infringe their right to ensure that infringing
                    products are removed from the site, as they erode Buyer and
                    good Seller trust.
                  </li>
                  <li>
                    Only the intellectual property rights owner can report
                    potentially infringing products or listings through TakeDown
                    Policy by way of Notice of infringement in the specified
                    format. If you are not the intellectual property rights
                    owner, you can still help by getting in touch with the
                    rights owner and encouraging them to contact us. (Note:
                    <span className="fw-medium">Jumuika Online</span> does not
                    and cannot verify that Merchants have the right or ability
                    to sell or distribute their listed products. However,{" "}
                    <span className="fw-medium">Jumuika Online</span> is
                    committed ensure that item listings do not infringe upon
                    intellectual property rights of third parties once an
                    authorized representative of the rights owner properly
                    reports them to{" "}
                    <span className="fw-medium">Jumuika Online</span>
                    .)
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">XVII Contact Us</h4>
            </div>
            Please contact us for any questions or comments (including all
            inquiries unrelated to copyright infringement) regarding this
            Platform.{" "}
            <span className="fw-medium">
              <span className="fw-medium">Jumuika Online</span>
            </span>{" "}
            1736.Kahama court, P.O. Box 20332 Dares Salaam, Tanzania. Email:
            support@jumuika.online Time: Mon - Sat (9:00 - 18:00)
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                XVIII. TERMS OF USE –{" "}
                <span className="fw-medium">Jumuika Online</span> ASSURED
              </h4>
            </div>
            <ol>
              <li>
                These terms of use (the " Terms") that govern your use of our
                service <span className="fw-medium">Jumuika Online</span>{" "}
                Assured (" <span className="fw-medium">Jumuika Online</span>{" "}
                Assured") on Platform. The services on Assured available on our
                Website and the App are jointly referred to as the " On-Time
                Delivery" more fully defined hereunder. Please read these Terms
                carefully before you use the{" "}
                <span className="fw-medium">Jumuika Online</span> Assured
                Services. If you do not agree to these Terms, you may not use
                the <span className="fw-medium">Jumuika Online</span>
                Assured Services. By using the Services, you signify your
                acceptance to the Terms and creates a legally binding
                arrangement to abide by the same.{" "}
              </li>
              <li>
                The <span className="fw-medium">Jumuika Online</span> Assured
                Services are made available on a best effort basis but not as
                obligation by <span className="fw-medium">Jumuika Online</span>{" "}
                LIMITED (hereinafter referred to as "
                <span className="fw-medium">Jumuika Online</span>
                ", " we", " us" or " our"). The{" "}
                <span className="fw-medium">Jumuika Online</span> Assured
                Services made available are subject to various factors beyond
                control. <span className="fw-medium">Jumuika Online</span> shall
                reserve the right to deny any part or full services without
                prior notice. " you" or " user" shall mean user/customer.{" "}
              </li>
              <li>
                "These Terms are special terms and as such restricted to only
                customer orders placed by customers through restaurants
                identified as <span className="fw-medium">Jumuika Online</span>{" "}
                Assured restaurants and as such do not supersede the{" "}
                <span className="fw-medium">Jumuika Online</span> Terms of Use,
                these Terms shall be read in conjunction with Website's Terms of
                Use."{" "}
              </li>
              <li>
                We reserve the right to modify these Terms at any time without
                notice posting changes on the Website and you shall be
                responsible to update yourself of such changes, if any, by
                accessing the changes on the Website. Your continued usage of
                the <span className="fw-medium">Jumuika Online</span> Assured
                Services after any change is posted constitutes your acceptance
                of the amended Terms.{" "}
              </li>
              <li>
                General Terms & Conditions pertaining to the products available
                on <span className="fw-medium">Jumuika Online</span> Assured:
                <ul>
                  <li>
                    In case the customer chooses to avail the On-Time Delivery
                    of the restaurants listed on Assured,{" "}
                    <span className="fw-medium">Jumuika Online</span> shall
                    guarantee the user delivery of the Order, in the estimated
                    time mentioned at the time of placing the Order, which time
                    shall be communicated to the customer by{" "}
                    <span className="fw-medium">Jumuika Online</span> (the "
                    ETA"), subject to these Terms.
                  </li>
                  <li>
                    We do not own, sell, resell, furnish, provide, prepare,
                    manage and/or control the Restaurants or the related
                    services provided in connection thereof.{" "}
                  </li>
                  <li>
                    All customers with{" "}
                    <span className="fw-medium">Jumuika Online</span> Account
                    can avail the On-Time Delivery of{" "}
                    <span className="fw-medium">Jumuika Online</span> Assured,
                    if they so choose to.
                  </li>
                  <li>
                    The delivery time is the time period between the customer
                    placing the order and the delivery executive arriving at the
                    first point of entry at the user's location, as shown on the
                    map at the time of placing the order (the " Delivery time").
                  </li>
                  <li>
                    The cashback amount will be credited to{" "}
                    <span className="fw-medium">Jumuika Online</span> Money only
                    and not to any other source that you may have used to make
                    such payment.
                  </li>
                  <li>
                    The cashback process will take time between 24-72 hours.
                  </li>
                </ul>
                <li>
                  In the event of the designated address falling outside the
                  delivery zone offered by us;
                </li>
                <ul>
                  <li>
                    Failure on the part of the customer to respond by phone or
                    email at the time of confirming the order booking;
                  </li>
                  <li>
                    Failure to deliver your order due to lack of information,
                    direction or authorization from you at the time of delivery;
                    or
                  </li>
                  <li>
                    <span className="fw-medium">Jumuika Online</span> reserves
                    the right not to enable Cashback Guarantee* if customer
                    location is different than indicated.
                  </li>
                </ul>
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> reserves
                rights to not enable Cashback Guarantee* in case of suspect
                fraudulent activity with a right to initiate appropriate legal
                recourse.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> does not
                penalize its Delivery Executives for late delivery. Nor does it
                charge the customer or the Restaurant anything extra for
                providing this On-Time Delivery.{" "}
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> is not
                investing or circulating any cash into any entity, restaurant or
                customer by way of providing this On-Time Delivery.{" "}
              </li>
              <li>
                This On-Time Delivery may not be applicable during holiday due
                to regional festivals or local events or at times of rain in any
                particular location,{" "}
                <span className="fw-medium">Jumuika Online</span> reserves the
                right to pause the On-Time Delivery without prior intimation.
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> reserves its
                right to alter/ withdraw/ extend any offers/ promotions at any
                time without giving any prior notice & without assigning any
                reason whatsoever.
              </li>
              <li>
                This is On-Time Delivery is subject to Force Majeure conditions.
              </li>
              <li>
                All disputes related to this On-Time Delivery will be subject to
                the exclusive jurisdiction of court of Dare salaam only.
              </li>
              <li>
                The On-Time Delivery shall not be available for bulk orders or
                orders exceeding TZS 250000/- (Two hundred and fifty thousand
                shillings).
              </li>
            </ol>
            These Terms and Conditions are co-extensive and concurrent with
            <span className="fw-medium">Jumuika Online</span>
            's other Terms and Conditions and the Privacy Policy listed on this
            page. As such, all other Terms and Conditions listed hereinbefore
            shall also be applicable to this On-Time Delivery and the same need
            not be expressly repeated herein.
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                XIX .Notice and Take Down Policy Template and Procedures
              </h4>
            </div>
            <span className="fw-medium">
              <span className="fw-medium">Jumuika Online</span>
            </span>{" "}
            (" Jumuika Online") respects third-party intellectual property
            rights, statutorily registered or common law rights, and has adopted
            the following policy towards intellectual property rights protection
            on its website www.jumuika.online (the " Website") and the 'Jumuika
            Online' application for mobile and handheld devices (the " App").
            The Website and the App are jointly referred to as the "Platform".
            <br />
            Reporting Instances of Intellectual Property Violations:
            <br />
            To file a notice of infringement with{" "}
            <span className="fw-medium">Jumuika Online</span>
            , you must provide a written communication (by email or regular
            mail) that sets out the information specified in the list below.
            Please note that you will be liable for damages (including costs and
            lawyer's fees) if you materially misrepresent that material is
            infringing your intellectual property right(s). Accordingly, if you
            are not sure if you are the proprietor of the intellectual property
            right(s), we suggest that you first contact a lawyer.
            <br />
            To expedite our ability to process your request, please use the
            following format:
            <ul>
              <li>
                Identify with sufficient detail the protected work that you
                believe has been infringed. This includes identification of the
                web page or specific posts, as opposed to entire sites. Posts
                must be referenced by either the dates in which they appear or
                by the permalink of the post. Include the URL to the concerned
                material infringing your intellectual property right(s) [URL of
                a website or URL to a post, with title, date, name of the
                emitter], or link to initial post with sufficient data to find
                it;
              </li>
              <li>
                Identification of the material claimed to be infringing or to be
                the subject of infringing activity and that is to be removed or
                access to which is to be disabled, and information reasonably
                sufficient to permit{" "}
                <span className="fw-medium">Jumuika Online</span> to locate the
                material;
              </li>
              <li>
                Information reasonably sufficient to permit{" "}
                <span className="fw-medium">Jumuika Online</span> to contact the
                complaining party, such as an address, telephone number, and, if
                available, an email address at which the complaining party may
                be contacted;
              </li>
              <li>
                The following mandatory statement: "I have a good faith belief
                that use of the material in the manner complained of is not
                authorized by the intellectual property right(s) owner, its
                agent, or the law";
              </li>
              <li>
                The following mandatory statement: "I swear, under penalty of
                perjury, that the information in the notification is accurate,
                and that I am the intellectual property right(s) owner or
                authorized to act on behalf of the owner of an exclusive
                intellectual property right(s) that is allegedly infringed";
              </li>
              <li>
                Send the written communication to: Email to
                support@jumuika.online
              </li>
            </ul>
          </div>
        </Stack>
      </Container>
    </Container>
  );
}

export default index;
