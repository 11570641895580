import React from "react";
import { Card, Button } from "react-bootstrap";
import "./style.scss";

function index() {
  return (
    <Card className="card_box">
      <Card.Img
        variant="top"
        className="card_image"
        src="https://picsum.photos/200/300"
      />
      <div className="card_price">
        <span>TZS 10,000</span>
        <span>-</span>
        <span>TZS 10,000</span>
      </div>
      <Card.Body>
        <span className="card_subtitle">Dar es Salaaam</span>
        <Card.Title className="card_title">Card Title</Card.Title>
        <Card.Text className="card_paragraph">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
      </Card.Body>
      <div className="d-flex flex-row justify-content-between border-top p-3">
        <div className="card_time">
          <i class="bi bi-clock-history"></i>
          <span className="ml-2">3 days 2 nights</span>
        </div>
        <div className="card_rating">
          <i class="rating_icon bi bi-stars"></i>
          <span>4.5</span>
        </div>
      </div>
    </Card>
  );
}

export default index;
