import React from "react";
import { Container, Stack, Table } from "react-bootstrap";

function index() {
  return (
    <Container fluid className="main_container">
      <Container>
        <div className="pt-2 pb-3">
          <h3 className="page_header">Cancellation & Refund Policy</h3>
        </div>
        <Stack gap={3}>
          <div>
            Any Capitalized terms used but not defined herein shall have the
            meaning assigned to them under the Terms of Use which govern your
            use of our website <span className="fw-medium">Jumuika Online</span>
            .in (the “Website”) and our ‘
            <span className="fw-medium">Jumuika Online</span>’ application for
            mobile and handheld devices (the “App”). The Website and the App are
            jointly referred to as the “Platform”.
          </div>
          <div>
            <div>
              <h4 className="Simple_header">1. Customer Cancellation</h4>
            </div>
            <ol>
              <li>
                As a general rule Buyer shall not be entitled to cancel Order
                once placed. Buyer may choose to cancel Order only within
                one-minute of the Order being placed. However, subject to
                Buyer’s previous cancellation history,{" "}
                <span className="fw-medium">Jumuika Online</span> reserves the
                right to deny any refund to Buyer pursuant to a cancellation
                initiated by Buyer even if the same is within one-minute
                followed by suspension of account, as may be necessary in the
                sole discretion of{" "}
                <span className="fw-medium">Jumuika Online</span>.
              </li>
              <li>
                If Buyer cancels his/her Order after one minute of placing it,
                <span className="fw-medium">Jumuika Online</span> shall have a
                right to collect a penalty of 100% of the Order amount for
                breach of contract terms as a compensation for the damages
                suffered by <span className="fw-medium">Jumuika Online</span>,
                with a right to either not to refund the Order value in case
                Buyer’s Order is prepaid or recover from the Buyer’s subsequent
                Order in case his/her Order is post-paid, to compensate the
                Vendors and <span className="fw-medium">Jumuika Online</span>{" "}
                delivery partners.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">2. Non-Customer Cancellation</h4>
            </div>
            <ol>
              <li>
                <span className="fw-medium">Jumuika Online</span> reserves the
                right to collect a penalty for the Orders constrained to be
                cancelled by <span className="fw-medium">Jumuika Online</span>{" "}
                for reasons not attributable to{" "}
                <span className="fw-medium">Jumuika Online</span>, including but
                not limited to:
                <ol>
                  <li>
                    In the event if the address provided by Buyer is either
                    wrong or falls outside the delivery zone;
                  </li>
                  <li>
                    Failure to contact Buyer by phone or email at the time of
                    delivering the Order;
                  </li>
                  <li>
                    Failure to deliver Buyer Order due to lack of information,
                    direction or authorization from Buyer at the time of
                    delivery; or
                  </li>
                  <li>
                    Unavailability of all the items ordered by Buyer at the time
                    of booking the Order; or
                  </li>
                  <li>
                    Unavailability of all the items ordered by Buyer at the time
                    of booking the Order. However, in the unlikely event of an
                    item in an Order being unavailable,{" "}
                    <span className="fw-medium">Jumuika Online</span> will
                    contact the Buyer on the phone number provided to us at the
                    time of placing the Order and inform Buyer of such
                    unavailability. In such an event Buyer will be entitled to
                    cancel the entire Order and shall be entitled to a refund to
                    an amount up to 100% of the Order value.
                  </li>
                </ol>
              </li>
              <li>
                In case of cancellations for the reasons attributable to{" "}
                <span className="fw-medium">Jumuika Online</span> or the Vendor
                or <span className="fw-medium">Jumuika Online</span> delivery
                partner, <span className="fw-medium">Jumuika Online</span> shall
                not collect any penalty from the Buyer.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">
                3. Cancellation policy for other than Food orders
              </h4>
            </div>
            <ol>
              <li>
                The Orders placed by Buyers using the Platform are
                non-cancellable and non-refundable except if refund is requested
                under the following conditions:
                <ol>
                  <li>
                    If the Order could not be delivered within the estimated
                    time while placing the order; If the Order has not been
                    picked by the{" "}
                    <span className="fw-medium">Jumuika Online</span> delivery
                    partner
                  </li>
                  <li>
                    If the Vendor doesn't accept or cancels the Order due to
                    reasons not attributable to Buyer, including but not limited
                    to store being closed, non-availability of items, store
                    cannot service online orders at that moment, store is
                    overcrowded, etc.
                  </li>
                  <li>
                    If <span className="fw-medium">Jumuika Online</span> cancels
                    the Order due to reasons not attributable to Buyer,
                    including but not limited to non-availability of{" "}
                    <span className="fw-medium">Jumuika Online</span>
                    delivery partner, etc.
                  </li>
                </ol>
              </li>
              <li>
                <span className="fw-medium">Jumuika Online</span> reserves the
                right to look into the cancellation request of the Buyer and
                determine if such cancelation request falls under the conditions
                mentioned above. If{" "}
                <span className="fw-medium">Jumuika Online</span> is satisfied
                that the request and same fulfils any of the aforesaid
                conditions, then
                <span className="fw-medium">Jumuika Online</span> shall process
                the cancellation request and refund amounts to the Buyer.
              </li>
            </ol>
          </div>
          <div>
            <div>
              <h4 className="Simple_header">4. Refunds</h4>
            </div>
            <ol>
              <li>
                Buyer may be entitled to a refund for prepaid Orders.{" "}
                <span className="fw-medium">Jumuika Online</span>
                retains the right to retain the penalty payable by the Buyer in
                Section 2.1 from the amount refundable to him/her.
              </li>
              <li>
                The Buyer shall also be entitled to a refund of proportionate
                value in the event packaging of an item in an Order or the
                complete Order, is either tampered or damaged and the Buyer
                refuses to accept at the time of delivery for the said reason;
                Buyer may be entitled to a refund up to 100% of the Order value
                if <span className="fw-medium">Jumuika Online</span> delivery
                partner fails to deliver the Order due to a cause attributable
                to either <span className="fw-medium">Jumuika Online</span>{" "}
                delivery partner or{" "}
                <span className="fw-medium">Jumuika Online</span>, however such
                refunds will be assessed on a case-to-case basis by
                <span className="fw-medium">Jumuika Online</span>.
              </li>
              <li>Our decision on refunds shall be final and binding.</li>
              <li>
                All refund amounts shall be credited to Buyer’s account as may
                be stipulated as per the payment mechanism of Buyer’s choice,
                the estimated timelines are detailed as below, in case Buyer
                don't choose to credit it to Buyer’s wallet with his/her{" "}
                <span className="fw-medium">Jumuika Online</span>
                Account;
              </li>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Process</th>
                    <th>Payment Method</th>
                    <th>Refund Source</th>
                    <th>TAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={3}>
                      Order Edit /Cancellation/Compensation/Payment Failure
                    </td>
                    <td>Net Banking</td>
                    <td>Source</td>
                    <td>5-7 Business Days</td>
                  </tr>
                  <tr>
                    <td>Debit/Credit Cards</td>
                    <td>Source</td>
                    <td>4-7 Business Days</td>
                  </tr>
                  <tr>
                    <td>Mobile Money</td>
                    <td>Source</td>
                    <td>4-7 Business Days</td>
                  </tr>
                </tbody>
              </Table>
              <li>
                In case of payment at the time of delivery, Buyer will not be
                required to pay for:
                <ol>
                  <li>
                    Orders where packaging is either tampered or damaged at the
                    time of delivery;
                  </li>
                  <li>Wrong Order being delivered; or</li>
                  <li>
                    Items missing from Buyer’s Order at the time of delivery
                  </li>
                </ol>
              </li>
            </ol>
            Provided the same is communicated to{" "}
            <span className="fw-medium">Jumuika Online</span> Customer Care
            through the Platform before the Order is marked delivered.
            <br />
            <br />
            In case of the complaint of any spurious product, the liability
            shall solely lie with the vendor selling the product.{" "}
            <span className="fw-medium">Jumuika Online</span> is merely
            facilitating the transactions between the Vendor and the Buyer and
            therefore, <span className="fw-medium">Jumuika Online</span> shall
            assume no liability with respect to the products sold by the Vendor.{" "}
            <span className="fw-medium">Jumuika Online</span> strictly
            discourages dealing of any spurious product on its Platform and
            shall reserve the right to report such incidents to the concerned
            authorities for appropriate legal actions.
          </div>
        </Stack>
      </Container>
    </Container>
  );
}

export default index;
