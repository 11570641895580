import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionHeader from "../../../../Base-Components/SectionHeader";
import Chartpie from "../../../../Assets/Images/Chartpie.png";
import Tours from "../../../../Assets/Images/Sliders.png";
import Flower from "../../../../Assets/Images/Image_Flower.png";
import Linegraph from "../../../../Assets/Images/Graph.png";
import "./style.scss";

function Statistics() {
  return (
    <Container>
      <Container>
        <SectionHeader
          align="center"
          header="Fastest Way to Book over 450 Great Events"
          subtitle="Discover how to quickly secure tickets to over 450 amazing events with our streamlined booking process."
        />
        <Row className="align-items-center justify-content-center">
          <Col xxl={2} xl={2} lg={3} md={3} sm={6} xs={6} className="p-2">
            <div className="statistic_box">
              <img src={Chartpie} alt="" className="statistic_icon" />
              <div className="statistic_body">
                <h4 className="statistic_number">140</h4>
                <span className="statistic_details">Air tickets sold</span>
              </div>
            </div>
          </Col>
          <Col xxl={2} xl={2} lg={3} md={3} sm={6} xs={6} className="p-2">
            <div className="statistic_box">
              <img src={Tours} alt="" className="statistic_icon" />
              <div className="statistic_body">
                <h4 className="statistic_number">140</h4>
                <span className="statistic_details">Air tickets sold</span>
              </div>
            </div>
          </Col>
          <Col xxl={2} xl={2} lg={3} md={3} sm={6} xs={6} className="p-2">
            <div className="statistic_box">
              <img src={Flower} alt="" className="statistic_icon" />
              <div className="statistic_body">
                <h4 className="statistic_number">140</h4>
                <span className="statistic_details">Air tickets sold</span>
              </div>
            </div>
          </Col>
          <Col xxl={2} xl={2} lg={3} md={3} sm={6} xs={6} className="p-2">
            <div className="statistic_box">
              <img src={Linegraph} alt="" className="statistic_icon" />
              <div className="statistic_body">
                <h4 className="statistic_number">140</h4>
                <span className="statistic_details">Air tickets sold</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Statistics;
