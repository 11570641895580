import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Stack, Form, Button } from "react-bootstrap";
import Logo from "../../Assets/Brand/JumuikaLogo.svg";
import AppStore from "../../Assets/Brand/AppStore.svg";
import PlayStore from "../../Assets/Brand/GooglePlay.svg";

import "./footer.scss";

function MainFooter() {
  return (
    <Container fluid className="mt-5 footer_container">
      <Container className="pt-3 pb-2">
        <Row className="mb-3">
          <Col xxl={3} xl={3} md={6} sm={12} className="pt-3 pb-3">
            <Stack gap={3}>
              <div>
                <img alt="" src={Logo} width={120} />
              </div>
              <div className="footer_paragraph">
                The starting point for your next project based on
                easy-to-customize Material-UI © helps you build apps faster and
                better.
              </div>
            </Stack>
          </Col>
          <Col xxl={3} xl={3} md={6} sm={12} className="pt-3 pb-3">
            <span className="footer_header">HELPS</span>
            <Stack gap={1} className="footer_list">
              <div className="footer_item">
                <Link className="footer_link">About Us</Link>
              </div>
              <div className="footer_item">
                <Link className="footer_link">Contact Us</Link>
              </div>
              <div className="footer_item">
                <Link className="footer_link">Refund & Cancellation</Link>
              </div>
              <div className="footer_item">
                <Link className="footer_link">Cookie Policy</Link>
              </div>
            </Stack>
          </Col>
          <Col xxl={3} xl={3} md={6} sm={12} className="pt-3 pb-3">
            <span className="footer_header">LEGAL</span>
            <Stack gap={1} className="footer_list">
              <div className="footer_item">
                <Link className="footer_link" to="/terms-of-use">
                  Terms & Conditions
                </Link>
              </div>
              <div className="footer_item">
                <Link className="footer_link" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </div>
              <div className="footer_item">
                <Link className="footer_link" to="/cancellation-refund-policy">
                  Refund & Cancellation
                </Link>
              </div>
              <div className="footer_item">
                <Link className="footer_link" to="/cookie-policy">
                  Cookie Policy
                </Link>
              </div>
            </Stack>
          </Col>
          <Col xxl={3} xl={3} md={6} sm={12} className="pt-3 pb-3">
            <Stack gap={4}>
              <div>
                <span className="footer_header">LET'S STAY IN TOUCH</span>
                <div className="footer_paragraph">
                  Ubscribe to our newsletter to receive latest articles to your
                  inbox weekly.
                </div>
                <div className="pt-2">
                  <Form className="d-flex justify-content-center footer_form">
                    <Form.Group
                      className="footer_formgroup"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        className="footer_input"
                        type="email"
                        placeholder="name@example.com"
                      />
                    </Form.Group>
                    <Button className="primary_button footer_btn">
                      Subscribe
                    </Button>
                  </Form>
                </div>
              </div>
              <div>
                <span className="footer_header">SOCIAL MEDIA</span>
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="footer_list pt-2 pb-2"
                >
                  <div className="footer_item">
                    <Link className="footer_link">
                      <i class="footer_social_icon bi bi-facebook"></i>
                    </Link>
                  </div>
                  <div className="footer_item">
                    <Link className="footer_link">
                      <i class="footer_social_icon bi bi-linkedin"></i>
                    </Link>
                  </div>
                  <div className="footer_item">
                    <Link className="footer_link">
                      <i class="footer_social_icon bi bi-twitter-x"></i>
                    </Link>
                  </div>
                  <div className="footer_item">
                    <Link className="footer_link">
                      <i class="footer_social_icon bi bi-instagram"></i>
                    </Link>
                  </div>
                  <div className="footer_item">
                    <Link className="footer_link">
                      <i class="footer_social_icon bi bi-threads"></i>
                    </Link>
                  </div>
                </Stack>
              </div>
              <div>
                <span className="footer_header">DOWNLOAD APP</span>
                <Stack direction="horizontal" gap={3} className="pt-3 pb-3">
                  <div>
                    <img alt="" src={AppStore} className="footer_store_icon" />
                  </div>
                  <div>
                    <img alt="" src={PlayStore} className="footer_store_icon" />
                  </div>
                </Stack>
              </div>
            </Stack>
          </Col>
        </Row>
        <Row className="border-top pt-3 pb-3">
          <Col
            xxl={6}
            xl={6}
            md={6}
            sm={12}
            className="text-center text-md-start"
          >
            <span className="footer_paragraph">
              © 2024. All rights reserved
            </span>
          </Col>
          <Col xxl={6} xl={6} md={6} sm={12}>
            <Stack
              direction="horizontal"
              gap={3}
              className="footer_list justify-content-center justify-content-md-end"
            >
              <div className="footer_item">
                <Link className="footer_link">Help Center</Link>
              </div>
              <div className="footer_item">
                <Link className="footer_link">Terms of Service</Link>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default MainFooter;
